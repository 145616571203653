/**
 * 消耗明细报表
 */
 import * as API from '@/api/index'

 export default {
     // 消耗报表
     getTreatDetailStatement: params => {
         return API.POST('api/entityTreatDetailStatement/list', params)
     },
     // 退消耗报表
     getTreatRefundDetailStatement: params => {
        return API.POST('api/entityTreatRefundDetailStatement/list', params)
    },


    // 消耗报表
    exportTreatDetailStatement: params => {
        return API.exportExcel('api/entityTreatDetailStatement/excel', params)
    },
    // 退消耗报表
    exportTreatRefundDetailStatement: params => {
       return API.exportExcel('api/entityTreatRefundDetailStatement/excel', params)
    },

 }